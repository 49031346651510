.App{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.App-title{
  height: 100px;
  display: flex;;
  justify-content: center;
  align-items: center;
}

.App-image{
  width: fit-content;
  display: flex;
  align-items: center;
}

.App-content{
  width: 90vw;
  font-size: 50px;
}